html {
  scroll-behavior: smooth;
}

#discord-toast{
  transform: translateX(-100%);
  opacity: 0;
  transition: opacity .25s, transform .25s;
  width: 0;
  height: fit-content;
}
#discord-toast.show {
  transform: translateX(0);
  animation: ToastAnimation 5s ease ;
  display: block !important;
}
@keyframes ToastAnimation {
  0% {
  width: 0;
  }
  40% {
    opacity: 1;
    width: 50%;
  }
  60% {
    width: 50%;
    opacity: 1;
  }
  90% {
    opacity: 0;
   width: 10%;
  }
  100%{
    width: 0;
    opacity: 0;
    display: none;
  }
}


body {
  background: linear-gradient(#D8BDAF, #D8BDFF) fixed;
  background-size: 400% 400%;

  -webkit-animation: BackgroundAnimation 30s ease infinite;
  -moz-animation: BackgroundAnimation 30s ease infinite;
  animation: BackgroundAnimation 30s ease infinite;
}

@-webkit-keyframes BackgroundAnimation {
  0% {
    background-position: 33% 0
  }
  50% {
    background-position: 68% 100%
  }
  100% {
    background-position: 33% 0
  }
}

@-moz-keyframes BackgroundAnimation {
  0% {
    background-position: 33% 0
  }
  50% {
    background-position: 68% 100%
  }
  100% {
    background-position: 33% 0
  }
}

@keyframes BackgroundAnimation {
  0% {
    background-position: 33% 0
  }
  50% {
    background-position: 68% 100%
  }
  100% {
    background-position: 33% 0
  }
}
.section {
  display: flex;
  justify-content: center;
  padding: 20px;
}

.section:nth-child(even) .card {
  flex-direction: row-reverse;
}

.card {
  max-width: 600px;
  margin: 0 auto;
  background-color: transparent;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

.card-content {
  text-align: center;
}

.card ul {
  text-align: left;
}

.card h1,
.card h6 {
  margin-top: 0;
}
